import React from "react"
import Section from "../../../components/section/Section"
import sovaImg from "../../../assets/images/decorations/why-sova/block-img-1.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  sovaImg: sovaImg,
}

const whySOVASectionSecond = ({ className, data }: { className: string; data: any }) => {
  const { heading, paragraphs, imgSrc, imgAlt } = data.data.content.WhySovaSectionSecondConfig
  return (
    <Section className={className}>
      <div className="sova-section-container">
        <h2 className="sova-section-child  sova-h2">{heading}</h2>
        <div className="sova-section-child-container sova-section-child_row">
          <div className="sova-section-child-container sova-section-child-container_whySOVA-4">
            {paragraphs.map((paragraph: string, key: number) => (
              <p key={key} className={`sova-section-child sova-section-paragraph sova-section-paragraph_whySOVA-2-${key} sova-section-child-text sova-p-tiny`}>
                {paragraph}
              </p>
            ))}
          </div>
          <img className="sova-section-child sova-section-img sova-section-img_whySOVA-whySOVASectionSecond" src={images[imgSrc]} alt={imgAlt} />
        </div>
      </div>
    </Section>
  )
}

whySOVASectionSecond.displayName = "whySOVASectionSecond"

export default whySOVASectionSecond
