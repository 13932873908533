import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../components/SEO/seo"

import WhySOVASections from "../pagesStructures/whySOVA/whySOVASections"

const WhySOVA = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiWhySova.content.find((el: any) => {
    return el.language === pageContext.lang
  })
  return (
    <Layout bgCustomClass="whySOVA">
      <SEO title={pageContent.content.title} />
      <WhySOVASections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default WhySOVA

export const query = graphql`
  query WhySOVA($url: String) {
    strapiWhySova(url: { eq: $url }) {
      content {
        content {
          title
          WhySOVASectionThirdConfig {
            btnLink
            btnText
            heading
            imgAlt
            imgSrc
            paragraphs
            statistics {
              img
              numbers
              title
            }
          }
          WhySovaSectionFirstConfig {
            btnLink
            btnText
            heading
            paragraph
            images {
              imgAlt
              imgSrc
            }
          }
          WhySovaSectionFourthConfig {
            heading
            imgAlt
            imgSrc
            paragraph
          }
          WhySovaSectionSecondConfig {
            heading
            imgAlt
            imgSrc
            paragraphs
          }
        }
        language
      }
    }
  }
`
