import React from "react"
import Section from "../../../components/section/Section"

import sovaImg from "../../../assets/images/decorations/why-sova/block-img-3.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  sovaImg: sovaImg,
}

const whySOVASectionFourth = ({ className, data }: { className: string; data: any }) => {
  const { heading, paragraph, imgSrc, imgAlt } = data.data.content.WhySovaSectionFourthConfig
  return (
    <Section className={className}>
      <div className="sova-section-container">
        <h2 className="sova-section-child  sova-h2">{heading}</h2>
        <div className="sova-section-child-container sova-section-child-container_row">
          <p className="sova-section-child sova-section-paragraph sova-section-child-text sova-p-tiny">{paragraph}</p>
          <img className="sova-section-child sova-section-img sova-section-img_whySOVA-whySOVASectionFourth" src={images[imgSrc]} alt={imgAlt} />
        </div>
      </div>
    </Section>
  )
}

whySOVASectionFourth.displayName = "whySOVASectionFourth"

export default whySOVASectionFourth
