import React from "react"
import Section from "../../../components/section/Section"

import sovaImg from "../../../assets/images/decorations/why-sova/block-img-2.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  sovaImg: sovaImg,
}

const whySOVASectionThird = ({ className, data }: { className: string; data: any }) => {
  const { heading, paragraphs, imgAlt, imgSrc, btnText, btnLink } = data.data.content.WhySOVASectionThirdConfig

  return (
    <Section className={className}>
      <h2 className="sova-section-child sova-h2">{heading}</h2>
      <div className="sova-section-child-container sova-section-child-container-whySOVA_3 sova-section-child_row">
        <div className="sova-section-child sova-section-img_whySOVA-whySOVASectionThird">
          <img src={images[imgSrc]} alt={imgAlt} className="sova-section-child sova-section-img sova-section-child_whySOVA-3" />
          {/* {statistics.map((el: any, i: number) => (
            <div key={i} className={`sova-section-child sova-section-child_row sova-section-child_whySOVA-3`}>
              <p className="sova-section-child sova-section-child_whySOVA-3-statistics sova-p-medium sova-p-medium_whySOVA-3-statistics">{el.numbers}</p>
              {el.img && <img src={el.img} alt="#" className="sova-section-child sova-section-child_whySOVA-3-statistics sova-section_whySOVA-3-statistics-img" />}
              <p className="sova-section-child sova-section-child_whySOVA-3-statistics sova-p-medium sova-p-medium_whySOVA-3-statistics">{el.title}</p>
            </div>
          ))} */}
          <a href={btnLink}>
            <button className="sova-btn sova-btn_secondary sova-btn_small">{btnText}</button>
          </a>
        </div>
        <div className="sova-section-child-container sova-section-child-container_whySOVA-3">
          {paragraphs.map((paragraph: string, key: number) => (
            <p key={key} className={`sova-section-child sova-section-paragraph sova-section-paragraph_whySOVA-3-${key} sova-section-child-text sova-p-tiny`}>
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    </Section>
  )
}

whySOVASectionThird.displayName = "whySOVASectionThird"

export default whySOVASectionThird
