import React from "react"

import WhySOVASectionFirst from "./whySOVASections/whySOVASectionFirst"
import WhySOVASectionSecond from "./whySOVASections/whySOVASectionSecond"
import WhySOVASectionThird from "./whySOVASections/whySOVASectionThird"
import WhySOVASectionFourth from "./whySOVASections/whySOVASectionFourth"

const sections = [
  { component: (key: number, className: string, data: any) => <WhySOVASectionFirst data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <WhySOVASectionSecond data={data} key={key} className={className} /> },
  { component: (key: number, className: string, data: any) => <WhySOVASectionThird data={data} key={key} className={className} /> },
  { component: (key: number, className: string, data: any) => <WhySOVASectionFourth data={data} key={key} className={className} /> },
]

const WhySOVASections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-whySOVA  sova-section-whySOVA_${i}`, data))}</>
}

WhySOVASections.displayName = "WhySOVASections"

export default WhySOVASections
