import React from "react"
import { Link } from "gatsby"
import Section from "../../../components/section/Section"
import firstBlockImg1 from "../../../assets/images/decorations/why-sova/first-block-img-1.svg"
import firstBlockImg2 from "../../../assets/images/decorations/why-sova/first-block-img-2.svg"

interface Imgs {
  [k: string]: string
}

const imgs: Imgs = {
  firstBlockImg1: firstBlockImg1,
  firstBlockImg2: firstBlockImg2,
}

const whySOVASectionFirst = ({ className, data }: { className: string; data: any }) => {
  const { heading, paragraph, btnText, btnLink, images } = data.data.content.WhySovaSectionFirstConfig

  return (
    <Section className={`${className} sova-fullscreen-height`}>
      <div className="sova-section-container sova-section-container-whySOVA-1">
        <h2 className="sova-section-child sova-h2">{heading}</h2>
        <p className="sova-section-child sova-section-paragraph sova-p-tiny sova-common-text-centered">{paragraph}</p>
        <Link to={`/${btnLink}`}>
          <button className="sova-btn sova-btn_primary sova-btn_big">{btnText}</button>
        </Link>
      </div>
      <div className="sova-section-container sova-section-container_row sova-section-container_whySOVA-whySOVASectionFirst">
        {images.map(({ imgSrc, imgAlt }: { imgSrc: any; imgAlt: string }, key: number) => (
          <img key={key} src={imgs[imgSrc]} alt={imgAlt} className={`sova-section-img sova-section-img_whySOVA-whySOVASectionFirst sova-section-img_whySOVA-whySOVASectionFirst-${key}`} />
        ))}
      </div>
    </Section>
  )
}

whySOVASectionFirst.displayName = "whySOVASectionFirst"

export default whySOVASectionFirst
